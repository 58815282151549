/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 3, 2022 */



@font-face {
    font-family: 'pixeboyregular';
    src: url('./fonts/pixeboy-z8xgd-webfont.woff2') format('woff2'),
         url('./fonts/pixeboy-z8xgd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sansation';
    src: url('./fonts/sansation_regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sansation';
    src: url('./fonts/sansation_bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'sansation';
    src: url('./fonts/sansation_italic.ttf');
    font-weight: italic;
}

@font-face {
    font-family: 'sansation';
    src: url('./fonts/sansation_light.ttf');
    font-weight: lighter;
}


body {
	font-family: 'Space Mono', serif;
 	background: #201C37;
 	color: #fff;
 }

a {
	color: #DB4261;
}

.btn-primary {
	background: #DB4261;
	color: #fff;
	border-color: #fff;
	border-radius: 0;
	box-shadow: 2px 3px 0 #201C37;

}

.btn-secondary {
	background: #1E1B2E;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 0;
	box-shadow: 1px 1px #fff;
}

.navbar {
	background: #201C37;
	border-bottom: 1px solid #5C4CB6;
}

.navbar a {
	font-family: 'Fuji';
	color: #fff;
	letter-spacing: .2em;
}

.navbar-brand {
color: #fff;
}


.what-is-fuji-lion {
	background: url('./images/bg.png') no-repeat;
	background-size: cover;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}


ul.timeline {
	list-style-type: none;
	position: relative;
}
ul.timeline:before {
	content: ' ';
	background: #d4d9df;
	display: inline-block;
	position: absolute;
	left: 29px;
	width: 2px;
	height: 100%;
	z-index: 400;
}
ul.timeline > li {
	margin: 20px 0;
	padding-left: 20px;
}
ul.timeline > li:before {
	content: ' ';
	background: url('./images/arrow.svg') no-repeat;
	display: inline-block;
	position: absolute;
	left: 20px;
	width: 20px;
	height: 40px;
	z-index: 400;
}

.card-body {
	margin: 3.5em 0 0 0;
	padding: 0;
}
.card-text li {
	font-size: .91em;
	color: #9FB5DE;
}

.border-right {
	border-right: 4px solid #383254 !important;
}
.border-left {
	border-left: 4px solid #383254 !important;
  text-align: center;
}

.card-title span {
	font-family: 'pixeboyregular';
	font-size: .8em;
	color: #DB4261;
	padding-right: 1em;
}

.badge {
	background: url('./images/arrow.svg') no-repeat;
}

.badge-pill {
	height: 30px;
	border-radius: 0 !important;
}

.pink {
	color: #DB4261;
}

.fw-light {
	font-weight: bold;
	margin-bottom: .8em;
}



.bg-section {
	background: url('./images/fuji-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: 20% 100%;
}

.bg-page {
  background: url('./images/bg.png') no-repeat;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: 20% 100%;
  padding-top: 2rem;
}

.minting-section {
	background: #1E1B2E;
	color: #9FB5DE;
  font-size: .92em;
}
.hheader {
  color: #839AC6;


}

.minting-section h2 {
	color: #E5ECF1;
	font-weight: bold;
}

.breeding-section {
	background: #fff;
	color: #839AC6;
	font-size: .8em;

	background: url('./images/bg2.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

.breeding-section h2 {
	color: #fff;
	font-weight: bold;
}

.breed-card {
	background: #F7F0F1;
	border: 20px solid #DB4261;
	box-shadow: 4px 4px 0 #000;
}

.blue-box {
	border: 1px solid #5C4CB6;
	background: #201C37;
}

.blue-box p {
	font-size: 1.3em;
  color: #E5ECF1;
}

.cost {
	color: #DB4261;
	font-weight: bold;
}

.fuji-border {
	background: url('./images/border-pink.svg') no-repeat;
	border: 0 !important;
}

.staking-section {
	background: url('./images/fuji-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
/*	background-position: 20% 100%;
	height: 1000px;*/
}

.footer {
	background: url('./images/bg.png');
	height: 300px;
	background-position: -90% -120%;
	position: relative;
}

@media (min-width: 576px) {
	.footer {
		background: url('./images/bg.png');
		height: 300px;
		background-position: 0 -120%;
		position: relative;
	}
}

.text-end {
  position: relative;
  margin: auto;
}

.minting-button.MuiButton-contained.Mui-disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.text-primary-color {
  color: var(--text-primary-color);
}

.mr-2 {
  margin-right: .5rem;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.MuiTableCell-root {
  padding: 16px;
}

th.MuiTableCell-root {
  border: 2px solid black !important;
}

td.MuiTableCell-root {
  border: 2px solid black !important;
}

#menu-appbar ul {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#menu-appbar ul li {
  display: block;
  text-align: left;
  background-color: #28213c;
}
#menu-appbar ul li a:hover {
  color: #FFF;
}
